import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../styles/Sidenavbar.scss";
import "../styles/livechat.scss";
import miniLogo from "../images/metric-favicon.svg";
import SalesBee from "../images/MatricLogo.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ORModal from "./ORModal";
import MenuFormat from "./MenuFormat";
import { useDispatch, useSelector } from "react-redux";
import { loadClientCategoryDrop } from "../redux/action";

function Sidenavbar(props) {
  const [open, setOpen] = useState(false);
  const [subId, setSubId] = useState("");
  let dispatch = useDispatch();

  const menuCollapse = props.menuCollapse;
  const { categoryDropdown } = useSelector((state) => state.data);

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const menuItems = MenuFormat(resPassData?.subscription?.modules);

  useEffect(() => {
    dispatch(loadClientCategoryDrop());
  }, []);
  // const [mainModalNames, setMainModalNames] = useState([]);

  // const [menuItems1, setMenuItems1] = useState([]);

  // useEffect(() => {
  //   let filterData = resPassData.subscription?.modules.filter(
  //     (item) => item.parentModuleName === null
  //   );
  //   let filterData1 = resPassData.subscription?.modules.filter(
  //     (item) => item.parentModuleName !== null
  //   );
  //   const combinedMenu = filterData.reduce((acc, currentItem) => {
  //     const existingRow = acc.find(
  //       (row) => row.mainModuleName === currentItem.mainModuleName
  //     );
  //     if (existingRow) {
  //       existingRow.ModuleInfo.push({
  //         mainModuleName: currentItem.mainModuleName,
  //         mainModuleType: currentItem.mainModuleType,
  //         moduleIcon: currentItem.moduleIcon,
  //         description: currentItem.description,
  //         moduleName: currentItem.moduleName,
  //         parentModuleName: currentItem.parentModuleName,
  //       });
  //     } else {
  //       acc.push({
  //         mainModuleName: currentItem.mainModuleName,
  //         mainModuleIcon: currentItem.mainModuleIcon,
  //         mainModuleID: currentItem.mainModuleID,
  //         ModuleInfo: [
  //           {
  //             mainModuleName: currentItem.mainModuleName,
  //             mainModuleType: currentItem.mainModuleType,
  //             moduleIcon: currentItem.moduleIcon,
  //             description: currentItem.description,
  //             moduleName: currentItem.moduleName,
  //             parentModuleName: currentItem.parentModuleName,
  //           },
  //         ],
  //       });
  //     }
  //     return acc;
  //   }, []);
  //   filterData1.forEach((item1) => {
  //     const matchingModule = combinedMenu.find(
  //       (item2) => item2.mainModuleName === item1.mainModuleName
  //     );
  //     if (matchingModule) {
  //       const matchingSubModule = matchingModule.ModuleInfo.find(
  //         (subItem) => subItem.moduleName === item1.parentModuleName
  //       );
  //       if (matchingSubModule) {
  //         if (!matchingSubModule.subMenus) {
  //           matchingSubModule.subMenus = [];
  //         }
  //         matchingSubModule.subMenus.push(item1);
  //       }
  //     }
  //   });
  //   let removeMasterMenu = combinedMenu.filter(
  //     (item) => item.mainModuleName !== "Master Management\r\n"
  //   );
  //   setMainModalNames(removeMasterMenu);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // console.log("MAIN-MODULES", mainModalNames);

  // const loggedInRole = resPassData?.roleId;

  // const GetImage = (img, defaultImg) => {
  //   return img != undefined &&
  //     img !== null &&
  //     img !== "" &&
  //     img !== "no_img.png"
  //     ? `${baseURL}/${img}`
  //     : defaultImg;
  // };
  // if (resPassData != null) {
  //   resPassData.profilePic = GetImage(resPassData?.profilePic, profileimg);
  // }

  // const [moduleID, setModuleID] = useState("");
  // const [openModule, setOpenModule] = useState(false);

  // let passedId = sessionStorage.getItem("userID");

  const subMenuOpen = (e) => {
    setSubId(e.target.id);
    setOpen(!open);
  };

  const [openQrModal, setOpenQrModal] = useState(false);
  const openAppQrCode = () => {
    setOpenQrModal(true);
  };

  const closeQrModal = () => {
    setOpenQrModal(false);
  };

  let clientCatId = window.location.hash.split("/").pop();
  let userId = window.location.hash.split("/").pop();

  return (
    <div>
      {openQrModal && (
        <ORModal openQrModal={openQrModal} closeQrModal={closeQrModal} />
      )}
      <div id="header">
        <div
          className={`pro-sidebar ${
            menuCollapse ? "collapsed-bar" : "expanded-bar"
          }`}
        >
          <div className="side_bar_header_main">
            <div className="logotext">
              {menuCollapse ? ( // If menuCollapse is true, render this block
                <div className="logo profile-mini">
                  <div className="profile-image">
                    <img src={miniLogo} alt="Profile-Img" />
                  </div>
                </div>
              ) : resPassData?.subscription?.company?.isWhiteList === 1 ? (
                <div className="big-logo">
                  <Link className="travelize_logo" to="/dashboard">
                    <img
                      src={resPassData?.subscription?.company?.isWhiteListPath}
                      alt="Company Logo"
                      className="salesbee-logo"
                      style={{ width: "150px", height: "60px" }}
                    />
                  </Link>
                </div>
              ) : (
                <Link className="travelize_logo" to="/dashboard">
                  <img
                    src={SalesBee}
                    alt="MetricInfo-Logo"
                    className="salesbee-logo"
                  />
                </Link>
              )}
            </div>
          </div>

          <div
            className="sidebar_content sidenavbar-scroll"
            id="sidenavAccordion"
          >
            {/* START NAV LINK ITEMS */}
            {!menuCollapse ? (
              <div>
                {menuItems &&
                  menuItems.map((menuItems, i) => (
                    <div className="expanded-nav-bar" key={i}>
                      {menuItems.name === "Dashboard" && (
                        <Link to="/dashboard">
                          <div
                            className={
                              window.location.hash === `#/dashboard` ||
                              window.location.hash === ""
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/desktop.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>

                                <span className="xn-text">Dashboard</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {menuItems.name === "Human Resource" && (
                        <div
                          className={
                            (subId === "Human Resource" && open) ||
                            window.location.hash === `#/HR/Users` ||
                            window.location.hash === `#/HR/Monthly` ||
                            window.location.hash === `#/HR/Leaves` ||
                            window.location.hash === `#/HR/Fullday` ||
                            window.location.hash === `#/HR/Fullday/${userId}`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="HUMANRESOURCE"
                            id="Human Resource"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "Human Resource" && open) ||
                              window.location.hash === `#/HR/Users` ||
                              window.location.hash === `#/HR/Monthly` ||
                              window.location.hash === `#/HR/Leaves` ||
                              window.location.hash === `#/HR/Fullday` ||
                              window.location.hash === `#/HR/Fullday/${userId}`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="Human Resource">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/users.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="HUMANRESOURCE"
                                id="Human Resource"
                              >
                                Human Resource
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="Human Resource"
                              />
                            </div>
                          </li>
                          {subId === "Human Resource" ||
                          window.location.hash === `#/HR/Users` ||
                          window.location.hash === `#/HR/Monthly` ||
                          window.location.hash === `#/HR/Leaves` ||
                          window.location.hash === `#/HR/Fullday` ||
                          window.location.hash === `#/HR/Fullday/${userId}` ? (
                            <>
                              {menuItems.subNames.map((subMenu, index) => (
                                <div key={index}>
                                  <Link to={`/HR/${subMenu.name}`}>
                                    <div
                                      className={
                                        window.location.hash ===
                                        `#/HR/${subMenu.name}`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        <div className="sub-icon-div">
                                          <img
                                            src={`../images/SideBar/${subMenu.icon}`}
                                            alt=""
                                            width="100%"
                                          />
                                          <span className="xn-text">
                                            {subMenu.name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {menuItems.name === "Travel Desk" && (
                        <Link to="/travelmanagement">
                          <div
                            className={
                              window.location.hash === `#/travelmanagement`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <span class="material-symbols-outlined menu-symbols">
                                    travel
                                  </span>
                                </div>
                                <span className="xn-text">Travel Desk</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Client Complaint" && (
                        <Link to="/complaints">
                          <div
                            className={
                              window.location.hash === `#/complaints`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/complaints.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Complaints</span>
                                <i
                                  className="bi-chevron-right toggle-btn"
                                  style={{ visibility: "hidden" }}
                                ></i>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Leaves" && (
                        <Link to="/leaves">
                          <div
                            className={
                              window.location.hash === `#/leaves`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/leaves.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Leaves</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Meetings" && (
                        <Link to="/meetings">
                          <div
                            className={
                              window.location.hash === `#/meetings`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/meetings.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Meetings</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {menuItems.name === "Clients" && (
                        <div
                          className={
                            (subId === "Clients" && open) ||
                            window.location.hash === `#/company/${clientCatId}`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <Link to="/company/0">
                            <li
                              name="CLIENTS"
                              id="Clients"
                              onClick={(e) => subMenuOpen(e)}
                              className={
                                (subId === "Clients" && open) ||
                                window.location.hash ===
                                  `#/company/${clientCatId}`
                                  ? "nav-link-item drop-down-item active"
                                  : "nav-link-item drop-down-item drop-main"
                              }
                            >
                              <div className="sidebar-title" id="Clients">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/company.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span
                                  className="xn-text"
                                  name="CLIENTS"
                                  id="Clients"
                                >
                                  Company
                                </span>
                                <i
                                  className="bi-chevron-right toggle-btn"
                                  id="Clients"
                                />
                              </div>
                            </li>
                          </Link>

                          {(subId === "Clients" && open) ||
                            (window.location.hash ===
                              `#/company/${clientCatId}` && (
                              <div
                                style={{
                                  maxHeight: "250px",
                                  overflow: "auto",
                                  marginRight: "0.25rem",
                                }}
                              >
                                {categoryDropdown.map((subMenu) => (
                                  <div>
                                    <Link to={`/company/${subMenu.value}`}>
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/company/${subMenu.value}`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            {subMenu.label}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      )}

                      {menuItems.name === "Orders" && (
                        <Link to="/orders">
                          <div
                            className={
                              window.location.hash === `#/orders`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/products.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">
                                  {menuItems.name}
                                </span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Products" && (
                        <Link to="/products">
                          <div
                            className={
                              window.location.hash === `#/products`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/products.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Products</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Routes" && (
                        <Link to="/routes">
                          <div
                            className={
                              window.location.hash === `#/routes`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/routes.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Routes</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Forms" && (
                        <Link to="/forms">
                          <div
                            className={
                              window.location.hash === `#/forms`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/forms.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Forms</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Spares" && (
                        <Link to="/spares">
                          <div
                            className={
                              window.location.hash === `#/spares`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/spares.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Spares</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Claims" && (
                        <div
                          className={
                            (subId === "claims" && open) ||
                            window.location.hash === `#/localclaim` ||
                            window.location.hash === `#/outstationclaim` ||
                            window.location.hash === `#/claimsummary` ||
                            window.location.hash === `#/travelReport`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="CLAIMS"
                            id="claims"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "claims" && open) ||
                              window.location.hash === `#/localclaim` ||
                              window.location.hash === `#/outstationclaim` ||
                              window.location.hash === `#/claimsummary` ||
                              window.location.hash === `#/travelReport`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="claims">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/claims.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="CLAIMS"
                                id="claims"
                              >
                                Claims
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="claims"
                              />
                            </div>
                          </li>

                          {(subId === "claims" && open) ||
                          window.location.hash === `#/localclaim` ||
                          window.location.hash === `#/outstationclaim` ||
                          window.location.hash === `#/claimsummary` ||
                          window.location.hash === `#/travelReport` ? (
                            <>
                              {menuItems.subNames.map((subMenu) => (
                                <div>
                                  {subMenu.name === "Local Claims" && (
                                    <Link to="/localclaim">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/localclaim`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Local Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Outstation Claims" && (
                                    <Link to="/outstationclaim">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/outstationclaim`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Outstation Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Summary Claims" && (
                                    <Link to="/claimsummary">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/claimsummary`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Summary Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Travel Report" && (
                                    <Link to="/travelReport">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/travelReport`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Travel Report
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {menuItems.name === "Connector" && (
                        <div
                          className={
                            (subId === "connect" && open) ||
                            window.location.hash === `#/user/referrals` ||
                            window.location.hash === `#/referenceInfo` ||
                            window.location.hash === `#/commission` ||
                            window.location.hash === `#/payment`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="CONNECTOR"
                            id="connector"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "connect" && open) ||
                              window.location.hash === `#/user/referrals` ||
                              window.location.hash === `#/referenceInfo` ||
                              window.location.hash === `#/commission` ||
                              window.location.hash === `#/payment`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="connect">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/connector.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="CONNECT"
                                id="connect"
                              >
                                Connector
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="connect"
                              />
                            </div>
                          </li>
                          {(subId === "connect" && open) ||
                          window.location.hash === `#/user/referrals` ||
                          window.location.hash === `#/referenceInfo` ||
                          window.location.hash === `#/commission` ||
                          window.location.hash === `#/payment` ? (
                            <>
                              {menuItems.subNames.map((subMenu, index) => (
                                <div key={index}>
                                  {subMenu.name === "Ref User" && (
                                    <Link to="/user/referrals">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/user/referrals`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            User Profile
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Reference Info" && (
                                    <Link to="/referenceInfo">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/referenceInfo`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Reference Info
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Commission" && (
                                    <Link to="/commission">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/commission`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Commission
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Payment" && (
                                    <Link to="/payment">
                                      <div
                                        className={
                                          window.location.hash === `#/payment`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Payment
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <>
                        {/* {resPassData?.subscriptionId === "SUB0000000001" && ( */}
                          <>
                            {/* VENDOR HELP DESK START */}
                          {/* {menuItems.name === "Connector" && (
                              <div
                                className={
                                  (subId === "connect" && open) ||
                                  window.location.hash === `#/vhd/dashboard` ||
                                  window.location.hash === `#/vhd/branch` ||
                                  window.location.hash === `#/vhd/vendors` ||
                                  window.location.hash ===
                                    `#/vhd/purchaseorders` ||
                                  window.location.hash === `#/vhd/podetails` ||
                                  window.location.hash === `#/vhd/invoices` ||
                                  window.location.hash ===
                                    `#/vhd/paymentstatus` ||
                                  window.location.hash ===
                                    `#/vhd/paymentdetails`
                                    ? "sidebar-item open"
                                    : "sidebar-item"
                                }
                              >
                                <li
                                  name="CONNECTOR"
                                  id="connector"
                                  onClick={(e) => subMenuOpen(e)}
                                  className={
                                    (subId === "connect" && open) ||
                                    window.location.hash ===
                                      `#/vhd/dashboard` ||
                                    window.location.hash === `#/vhd/branch` ||
                                    window.location.hash === `#/vhd/vendors` ||
                                    window.location.hash ===
                                      `#/vhd/purchaseorders` ||
                                    window.location.hash ===
                                      `#/vhd/podetails` ||
                                    window.location.hash === `#/vhd/invoices` ||
                                    window.location.hash ===
                                      `#/vhd/paymentstatus` ||
                                    window.location.hash ===
                                      `#/vhd/paymentdetails`
                                      ? "nav-link-item drop-down-item active"
                                      : "nav-link-item drop-down-item drop-main"
                                  }
                                >
                                  <div className="sidebar-title" id="connect">
                                    <div className="fa-icon-div">
                                      <img
                                        src="../images/SideBar/connector.svg"
                                        alt=""
                                        width="100%"
                                      />
                                    </div>
                                    <span
                                      className="xn-text"
                                      name="CONNECT"
                                      id="connect"
                                    >
                                      VHD
                                    </span>
                                    <i
                                      className="bi-chevron-right toggle-btn"
                                      id="connect"
                                    />
                                  </div>
                                </li>
                                {(subId === "connect" && open) ||
                                window.location.hash === `#/vhd/dashboard` ||
                                window.location.hash === `#/vhd/branch` ||
                                window.location.hash === `#/vhd/vendors` ||
                                window.location.hash ===
                                  `#/vhd/purchaseorders` ||
                                window.location.hash === `#/vhd/podetails` ||
                                window.location.hash === `#/vhd/invoices` ||
                                window.location.hash ===
                                  `#/vhd/paymentstatus` ||
                                window.location.hash ===
                                  `#/vhd/paymentdetails` ? (
                                  <>
                                    {menuItems.subNames.map(
                                      (subMenu, index) => (
                                        <div key={index}>
                                          {subMenu.name === "Ref User" && (
                                            <Link to="/vhd/dashboard">
                                              <div
                                                className={
                                                  window.location.hash ===
                                                  `#/vhd/dashboard`
                                                    ? `sidebar-content sub-active`
                                                    : `sidebar-content`
                                                }
                                              >
                                                <div className="nav-link-item">
                                                  <span className="bi bi-check-square-fill"></span>

                                                  <span className="xn-text">
                                                    Dashboard
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          )}
                                          {subMenu.name ===
                                            "Reference Info" && (
                                            <Link to="/vhd/branch">
                                              <div
                                                className={
                                                  window.location.hash ===
                                                  `#/vhd/branch`
                                                    ? `sidebar-content sub-active`
                                                    : `sidebar-content`
                                                }
                                              >
                                                <div className="nav-link-item">
                                                  <span className="bi bi-check-square-fill"></span>
                                                  <span className="xn-text">
                                                    Branch
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          )}
                                          {subMenu.name === "Commission" && (
                                            <Link to="/vhd/vendors">
                                              <div
                                                className={
                                                  window.location.hash ===
                                                  `#/vhd/vendors`
                                                    ? `sidebar-content sub-active`
                                                    : `sidebar-content`
                                                }
                                              >
                                                <div className="nav-link-item">
                                                  <span className="bi bi-check-square-fill"></span>
                                                  <span className="xn-text">
                                                    Vendors
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          )}
                                          {subMenu.name === "Payment" && (
                                            <Link to="/vhd/purchaseorders">
                                              <div
                                                className={
                                                  window.location.hash ===
                                                    `#/vhd/purchaseorders` ||
                                                  window.location.hash ===
                                                    `#/vhd/podetails`
                                                    ? `sidebar-content sub-active`
                                                    : `sidebar-content`
                                                }
                                              >
                                                <div className="nav-link-item">
                                                  <span className="bi bi-check-square-fill"></span>

                                                  <span className="xn-text">
                                                    Purchase Orders
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          )}
                                          {subMenu.name === "Payment" && (
                                            <Link to="/vhd/invoices">
                                              <div
                                                className={
                                                  window.location.hash ===
                                                  `#/vhd/invoices`
                                                    ? `sidebar-content sub-active`
                                                    : `sidebar-content`
                                                }
                                              >
                                                <div className="nav-link-item">
                                                  <span className="bi bi-check-square-fill"></span>

                                                  <span className="xn-text">
                                                    Invoice Status
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          )}
                                          {subMenu.name === "Payment" && (
                                            <Link to="/vhd/paymentstatus">
                                              <div
                                                className={
                                                  window.location.hash ===
                                                    `#/vhd/paymentstatus` ||
                                                  window.location.hash ===
                                                    `#/vhd/paymentdetails`
                                                    ? `sidebar-content sub-active`
                                                    : `sidebar-content`
                                                }
                                              >
                                                <div className="nav-link-item">
                                                  <span className="bi bi-check-square-fill"></span>

                                                  <span className="xn-text">
                                                    Payment Status
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            )} */}
                            {/* VENDOR HELP DESK END */}
                          </>
                        {/* )} */}
                      </>
                    </div>
                  ))}
              </div>
            ) : (
              //  COLLAPSED NAV BAR START
              <div>
                {menuItems &&
                  menuItems.map((menuItems, i) => (
                    <div className="collapsed-nav-bar" key={i}>
                      {menuItems.name === "Dashboard" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-dashboard`}>
                              Dashboard
                            </Tooltip>
                          }
                        >
                          <li id="#dashboard">
                            <NavLink to="/dashboard">
                              <img
                                src="../images/SideBar/desktop.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Human Resource" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-users`}>
                              Human Resource
                            </Tooltip>
                          }
                        >
                          <li id="#users">
                            <NavLink to="/users">
                              <img
                                src="../images/SideBar/users.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Meetings" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>Meetings</Tooltip>
                          }
                        >
                          <li id="meetings">
                            <NavLink to="/meetings">
                              <img
                                src="../images/SideBar/meetings.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Clients" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-company`}>Company</Tooltip>
                          }
                        >
                          <li id="company" className="xn-openable">
                            <NavLink to="/company">
                              <img
                                src="../images/SideBar/company.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Products" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-products`}>Products</Tooltip>
                          }
                        >
                          <li id="products" className="xn-openable">
                            <NavLink to="/products">
                              <img
                                src="../images/SideBar/products.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Spares" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-spares`}>Spares</Tooltip>
                          }
                        >
                          <li id="#spares">
                            <NavLink to="/spares">
                              <img
                                src="../images/SideBar/spares.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Forms" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-forms`}>Forms</Tooltip>
                          }
                        >
                          <li id="forms">
                            <NavLink to="/forms">
                              <img
                                src="../images/SideBar/forms.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Connector" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-connector`}>
                              Connector
                            </Tooltip>
                          }
                        >
                          <li id="connector">
                            <NavLink to="/refferal">
                              <img
                                src="../images/SideBar/connector.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Travel Desk" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-travelmanagement`}>
                              Travel Desk
                            </Tooltip>
                          }
                        >
                          <li id="travelmanagement">
                            <NavLink to="/travelmanagement">
                              <img
                                src="../images/SideBar/meetings.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Orders" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-orders`}>Orders</Tooltip>
                          }
                        >
                          <li id="orders">
                            <NavLink to="/orders">
                              <img
                                src="../images/SideBar/products.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Client Complaint" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>
                              Complaints
                            </Tooltip>
                          }
                        >
                          <li id="complaints">
                            <NavLink to="/complaints">
                              <img
                                src="../images/SideBar/complaints.svg"
                                alt="complaints-logo"
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Claims" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>Claim</Tooltip>
                          }
                        >
                          <li id="claims" className="xn-openable">
                            <NavLink to="/localclaim">
                              <img
                                src="../images/SideBar/claims.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Routes" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-routes`}>Routes</Tooltip>
                          }
                        >
                          <li id="routes">
                            <NavLink to="/routes">
                              <img
                                src="../images/SideBar/routes.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                    </div>
                  ))}
              </div>
              //  COLLAPSED NAV BAR END
            )}

            {/* END NAV LINK ITEMS */}
          </div>
          <div>
            <div className="downloadApp-part" onClick={openAppQrCode}>
              <div className="download-app">
                {!menuCollapse ? (
                  <>
                    <div className="material-symbols-outlined">download</div>
                    <div className="text">Download App</div>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement={"right"}
                      delay={{ show: 250, hide: 300 }}
                      overlay={
                        <Tooltip id={`tooltip-meetings`}>Download App</Tooltip>
                      }
                    >
                      <div className="material-symbols-outlined">download</div>
                    </OverlayTrigger>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidenavbar;
